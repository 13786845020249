.impress-margin {
  margin-left: 20px;
  margin-right: 20px;
}

.centering {
  max-width: 400px;
  margin: 0 auto;
}

.centerios {
  text-align: center;
}

.impress {
  text-decoration: underline;
  color: steelblue;
  cursor: pointer;
}

.unterstrich {
  text-decoration: underline;
  color: #f37121;
}

.valida {
  color: #f37121;
  font-weight: bold;
  font-size: 0.75em;
}

.korrekt {
  color: #538135;
  font-weight: bold;
}

.top-margin {
  margin-top: 200px;
}

.orders-filter {
  position: sticky;
  height: 40px;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

.card-title-class {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  font-weight: bold;
  color: #555;
}

.filter-underlined {
  border-bottom: solid 2px;
  border-bottom-color: coral;
  font-weight: bold;
}

.allergene {
  text-align: start;
}

.antwort {
  margin: 10px 15px;
  color: #555;
  font-size: 0.85em;
}

.billard {
  font-weight: bold;
}

.mail-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: bold;
  color: #555;
  font-size: 0.75em;
}

.ueberschrift {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: bold;
  color: darkslategrey;
}

.margir {
  margin-left: 15px;
}

.kursiv {
  font-style: italic;
}

.sfragen {
  text-align: start;

  color: #555;
}

.anders {
  font-weight: bold;
  color: #555;
}

.gumbl {
  color: #ffc000;
  font-size: 1.75em;
  margin: 5px;
}

.mail {
  color: #538135;
  font-size: 1.2em;
}

button {
  margin-top: 10px;
  padding: 8px 12px;
  background: #ffc000;
  border: 0;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  border-radius: 7px !important;
  color: #538135;
}

.ten-margin {
  margin-bottom: 10px;
}

.inputb {
  border: 2px solid #538135;
  border-radius: 5px;
  color: #538135;
  padding: 8px;
}

.margin-around {
  margin: 10px;
}

.antwort {
  width: 220px;
}

.underly {
  text-decoration: underline;
}

.viz {
  visibility: hidden;
}

.margl {
  border: 1px solid #ffc000;
  padding-left: 5px;
  padding-right: 5px;
}

.fixim {
  position: fixed;
  bottom: 0px;
}
